<template>
	<v-sheet class="create-Customer create-page" id="customer" style="margin: 0 -12px">
		<div class="container">
			<v-row class="sticky">
				<v-col md="6" class="my-auto">
					<h1 class="custom-header-blue-text m-0">Create Recurring</h1>
				</v-col>
				<v-col md="6" class="text-right">
					<v-btn
						:loading="pageLoading"
						class="white--text mr-2"
						depressed
						color="blue darken-4"
						tile
						:disabled="pageLoading || !formValid"
						v-on:click="update_or_create()"
					>
						Save
					</v-btn>
					<v-btn :disabled="pageLoading" depressed tile v-on:click="goBack()"> Cancel </v-btn>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="9">
					<div class="form-grp-title">
						<div>Sales And Customer Contact Person</div>
						<small
							><v-icon size="16">mdi-progress-question</v-icon> Specify the sales and contact person
							details
						</small>
					</div>
					<v-form
						ref="form"
						id="form"
						v-model.trim="formValid"
						lazy-validation
						v-on:submit.stop.prevent="update_or_create"
					>
						<v-row class="mb-3 px-4">
							<v-col lg="4" cols="12" class="my-auto py-0">
								<label class="input-label required">Sales Person</label>
							</v-col>
							<v-col lg="8" class="my-auto py-0">
								<v-autocomplete
									:items="usersList"
									hide-details
									outlined
									placeholder="Select Sales Person"
									v-model="recurring.sales_person"
									item-text="display_name"
									item-value="id"
									:rules="[vrules.required(recurring.sales_person, 'Sales Person')]"
									:class="{
										required: !recurring.sales_person,
									}"
								>
								</v-autocomplete>
							</v-col>
						</v-row>
						<v-row class="mb-3 px-4">
							<v-col lg="4" cols="12" class="my-auto py-0">
								<label class="input-label required">Contact Person </label>
							</v-col>
							<v-col lg="8" class="my-auto py-0">
								<v-text-field
									hide-details
									outlined
									placeholder="Contact Person"
									v-model="recurring.contact_person"
									:rules="[vrules.required(recurring.contact_person, 'Contact Person')]"
									:class="{
										required: !recurring.contact_person,
									}"
								>
								</v-text-field>
							</v-col>
						</v-row>
						<v-row class="mb-3 px-4">
							<v-col lg="4" cols="12" class="my-auto py-0">
								<label class="input-label required">Company </label>
							</v-col>
							<v-col lg="8" class="my-auto py-0">
								<v-text-field
									hide-details
									outlined
									placeholder="Company Name"
									v-model="recurring.company_name"
									:rules="[vrules.required(recurring.company_name, 'Company Name')]"
									:class="{
										required: !recurring.company_name,
									}"
								>
								</v-text-field>
							</v-col>
						</v-row>
						<v-row class="mb-3 px-4">
							<v-col lg="4" cols="12" class="my-auto py-0">
								<label class="input-label required">Phone</label>
							</v-col>
							<v-col lg="4" class="my-auto py-0" style="margin-bottom: -6px !important">
								<PhoneTemplate
									required
									hide-details
									:disabled="pageLoading"
									:loading="pageLoading"
									id="phone_number"
									v-model="recurring.primary_number"
								></PhoneTemplate>
							</v-col>
							<v-col lg="4" class="my-auto py-0" style="margin-bottom: -6px !important">
								<PhoneTemplate
									hide-details
									:disabled="pageLoading"
									:loading="pageLoading"
									id="phone_number"
									v-model="recurring.secondry_number"
									:class="{
										required: !recurring.company_name,
									}"
								></PhoneTemplate>
							</v-col>
						</v-row>
						<v-row class="mb-3 px-4">
							<v-col lg="4" cols="12" class="my-auto py-0">
								<label class="input-label required">Email</label>
							</v-col>
							<v-col lg="4" class="my-auto py-0">
								<v-text-field
									hide-details
									outlined
									placeholder="Primary Email"
									v-model="recurring.primary_email"
									:rules="[
										vrules.required(recurring.primary_email, 'Primary Email'),
										vrules.validEmail(recurring.primary_email, 'Primary Email'),
									]"
									:class="{
										required: !recurring.primary_email,
									}"
								>
								</v-text-field>
							</v-col>
							<v-col lg="4" class="my-auto py-0">
								<v-text-field
									hide-details
									outlined
									placeholder="Secondary Email"
									:rules="[
										vrules.required(recurring.secondry_email, 'Secondary Email'),
										vrules.validEmail(recurring.secondry_email, 'Secondary Email'),
									]"
									v-model="recurring.secondry_email"
								>
								</v-text-field>
							</v-col>
						</v-row>
						<v-row class="mb-3 px-4">
							<v-col lg="4" cols="12" class="my-auto py-0">
								<label class="input-label">Designation</label>
							</v-col>
							<v-col lg="8" class="my-auto py-0">
								<v-text-field
									hide-details
									outlined
									placeholder="Designation"
									v-model="recurring.designation"
								>
								</v-text-field>
							</v-col>
						</v-row>
						<div class="form-grp-title mt-3">
							<div>
								<div>Attachments</div>
								<small>
									<v-icon size="16">mdi-progress-question</v-icon> Specify the attachments for your Leads
								</small>
							</div>
						</div>
						<template v-for="(file, index) in files">
							<v-row class="mb-3 px-4" :key="index">
								<v-col lg="5" cols="5" class="my-auto py-0 d-flex align-items-center">
									<v-file-input
										:id="`document-file-${index}`"
										placeholder="Select File"
										outlined
										class="mt-3"
										prepend-icon=""
										prepend-inner-icon="mdi-attachment"
										hide-details
										v-model="file.file"
										v-on:change="updateFile(index, $event)"
										v-on:click:clear="updateFile(index, $event)"
									></v-file-input>
								</v-col>
								<v-col lg="" cols="5" class="my-auto py-0 d-flex align-items-center">
									<TextInput
										:id="`document-name-${index}`"
										v-model="file.name"
										hide-details
										placeholder="File Name"
										:suffix="file.suffix"
									></TextInput>
								</v-col>
								<v-col lg="1" cols="1" class="my-auto py-0">
									<v-btn
										:disabled="files.length < 2"
										v-on:click="removeFile(index)"
										color="red lighten-1 white--text"
										class="mt-3"
										icon
									>
										<v-icon>mdi-delete</v-icon>
									</v-btn>
								</v-col>
								<v-col lg="1" cols="1" class="my-auto py-0">
									<v-btn
										color="blue darken-4 white--text"
										class="mt-2 ml-2"
										tile
										depressed
										v-on:click="addMore()"
									>
										<v-icon>mdi-plus</v-icon>
									</v-btn>
								</v-col>
							</v-row>
						</template>
						<div class="form-grp-title">
							<div>Address Details</div>
							<small><v-icon size="16">mdi-progress-question</v-icon> Specify the address details </small>
						</div>
						<v-row class="mb-3 px-4">
							<v-col lg="4" cols="12" class="my-auto py-0">
								<label class="input-label required">Postal Code</label>
							</v-col>
							<v-col lg="8" class="my-auto py-0">
								<v-text-field
									v-model.trim="recurring.postal_code"
									:rules="[
										vrules.required(recurring.postal_code, 'Postal Code'),
										vrules.maxLength(recurring.postal_code, 'Postal Code', 10),
									]"
									v-mask="'######'"
									type="Number"
									:class="{
										required: !recurring.postal_code,
									}"
									hide-details
									outlined
									placeholder="Postal Code"
								>
								</v-text-field>
							</v-col>
						</v-row>
						<v-row class="mb-3 px-4">
							<v-col lg="4" cols="12" class="my-auto py-0">
								<label class="input-label required">Address Line 1</label>
							</v-col>
							<v-col lg="8" class="my-auto py-0">
								<v-text-field
									hide-details
									outlined
									placeholder="Address Line 1"
									v-model="recurring.address_line_1"
									:rules="[vrules.required(recurring.address_line_1, 'Address Line 1')]"
									:class="{
										required: !recurring.address_line_1,
									}"
								>
								</v-text-field>
							</v-col>
						</v-row>
						<v-row class="mb-3 px-4">
							<v-col lg="4" cols="12" class="my-auto py-0">
								<label class="input-label required">Address Line 2</label>
							</v-col>
							<v-col lg="8" class="my-auto py-0">
								<v-text-field
									hide-details
									outlined
									placeholder="Address Line 2"
									v-model="recurring.address_line_2"
									:rules="[vrules.required(recurring.address_line_2, 'Address Line 2')]"
									:class="{
										required: !recurring.address_line_2,
									}"
								>
								</v-text-field>
							</v-col>
						</v-row>
						<v-row class="mb-3 px-4">
							<v-col lg="4" cols="12" class="my-auto py-0">
								<label class="input-label">Unit Number</label>
							</v-col>
							<v-col lg="8" class="my-auto py-0">
								<v-text-field
									hide-details
									outlined
									placeholder="Unit Number"
									v-model="recurring.unit_number"
								>
								</v-text-field>
							</v-col>
						</v-row>
						<v-row class="mb-3 px-4">
							<v-col lg="4" cols="12" class="my-auto py-0">
								<label class="input-label">Country</label>
							</v-col>
							<v-col lg="8" class="my-auto py-0">
								<v-autocomplete
									:items="countryList"
									hide-details
									outlined
									placeholder="Country"
									v-model="recurring.country"
									item-text="text"
									item-value="value"
								>
								</v-autocomplete>
							</v-col>
						</v-row>
						<v-row class="mb-3 px-4">
							<v-col lg="4" cols="12" class="my-auto py-0">
								<label class="input-label">Currency </label>
							</v-col>
							<v-col lg="8" class="my-auto py-0">
								<v-autocomplete
									:items="currencyList"
									hide-details
									outlined
									placeholder="Currency"
									v-model="recurring.currency"
									item-text="text"
									item-value="value"
								>
								</v-autocomplete>
							</v-col>
						</v-row>
						<div class="form-grp-title">
							<div>Project Details</div>
							<small> <v-icon size="16">mdi-progress-question</v-icon> Specify the project details </small>
						</div>
						<v-row class="mb-3 px-4">
							<v-col lg="4" cols="12" class="my-auto py-0">
								<label class="input-label required">Project Type</label>
							</v-col>
							<v-col lg="8" class="my-auto py-0">
								<v-autocomplete
									:items="projectTypeList"
									multiple
									hide-details
									outlined
									placeholder="Project Type"
									item-text="name"
									item-value="value"
									v-model="recurring.project_type"
									:rules="[vrules.required(recurring.project_type, 'Project Type')]"
									:class="{
										required: !recurring.project_type,
									}"
								>
								</v-autocomplete>
							</v-col>
						</v-row>
						<v-row class="mb-3 px-4">
							<v-col lg="4" cols="12" class="my-auto py-0">
								<label class="input-label">KWS </label>
							</v-col>
							<v-col lg="4" class="my-auto py-0">
								<v-autocomplete
									:items="kwsList"
									hide-details
									outlined
									placeholder="KWS"
									v-model="recurring.kws"
								>
								</v-autocomplete>
							</v-col>
							<v-col lg="4" class="my-auto py-0">
								<v-autocomplete
									:items="kwsList"
									hide-details
									outlined
									placeholder="KWS"
									v-model="recurring.kws"
								>
								</v-autocomplete>
							</v-col>
						</v-row>
						<v-row class="mb-3 px-4">
							<v-col lg="4" cols="12" class="my-auto py-0">
								<label class="input-label">Web URL (Target) </label>
							</v-col>
							<v-col lg="8" class="my-auto py-0">
								<v-autocomplete
									:items="Web_urls"
									hide-details
									outlined
									placeholder="Web URL (Target)"
									v-model="recurring.web_url"
								>
								</v-autocomplete>
							</v-col>
						</v-row>
						<v-row class="mb-3 px-4">
							<v-col lg="4" cols="12" class="my-auto py-0">
								<label class="input-label">Main Contract </label>
							</v-col>
							<v-col lg="4" class="my-auto py-0">
								<v-text-field
									type="text"
									hide-details
									outlined
									placeholder="Main Contract"
									v-model="recurring.main_contact"
								>
								</v-text-field>
							</v-col>
							<v-col lg="4" class="my-auto py-0">
								<v-text-field
									type="number"
									hide-details
									outlined
									placeholder="Offered Months"
									v-model="recurring.offered_month"
								>
								</v-text-field>
							</v-col>
						</v-row>
						<div class="form-grp-title">
							<div>Timeframe & Sales Details</div>
							<small>
								<v-icon size="16">mdi-progress-question</v-icon> Specify the Timeframe & Sales Details
							</small>
						</div>
						<v-row class="mb-3 px-4">
							<v-col lg="4" cols="12" class="my-auto py-0">
								<label class="input-label">Contract Period</label>
							</v-col>
							<v-col lg="2" cols="12" class="my-auto py-0">
								<v-autocomplete
									:items="contact_period_list"
									hide-details
									v-model="recurring.period_time"
									outlined
									placeholder="Payment Period"
									item-text="name"
									item-value="value"
									v-on:change="get_contact_period_values"
								></v-autocomplete>
							</v-col>
							<v-col lg="3" class="my-auto py-0">
								<DatePicker
									hide-details
									outlined
									custom-class="mt-0"
									v-model="recurring.contact_start_date"
									v-on:change="get_contact_period_values"
								></DatePicker>
							</v-col>
							<v-col lg="3" class="my-auto py-0">
								<DatePicker
									hide-details
									outlined
									custom-class="mt-0"
									v-model="recurring.contact_end_date"
								></DatePicker>
							</v-col>
						</v-row>
						<v-row class="mb-3 px-4">
							<v-col lg="4" cols="12" class="my-auto py-0">
								<label class="input-label">Sales Date</label>
							</v-col>
							<v-col lg="4" class="my-auto py-0 d-flex">
								<DatePicker
									hide-details
									outlined
									custom-class="mt-0"
									v-model="recurring.sales_date"
								></DatePicker>
								<v-btn
									tile
									depressed
									:color="is_primary ? 'blue darken-4 white--text' : ''"
									height="30"
									min-height="30"
									class="border"
									v-on:click="is_primary = !is_primary"
								>
									Set Primary
								</v-btn>
							</v-col>
							<v-col lg="4" class="my-auto py-0 d-flex">
								<DatePicker
									hide-details
									outlined
									custom-class="mt-0"
									placeholder="Invoice Date"
									v-model="recurring.invoice_primary_date"
								></DatePicker>
								<v-btn
									tile
									depressed
									:color="!is_primary ? 'blue darken-4 white--text' : ''"
									height="30"
									min-height="30"
									class="border"
									v-on:click="is_primary = !is_primary"
								>
									Set Primary
								</v-btn>
							</v-col>
						</v-row>
						<v-row class="mb-3 px-4" v-if="!is_primary">
							<v-col lg="4" cols="12" class="my-auto py-0">
								<label class="input-label"></label>
							</v-col>
							<v-col lg="4" class="my-auto py-0">
								<label class="input-label mb-1">Invoice Number</label>
								<v-text-field
									hide-details
									v-model="recurring.invoice_number"
									outlined
									placeholder="Invoice Number"
								></v-text-field>
							</v-col>
							<v-col lg="4" class="my-auto py-0">
								<label class="input-label mb-1">Invoice Date</label>
								<DatePicker
									hide-details
									outlined
									custom-class="mt-0"
									placeholder="Invoice Date"
									v-model="recurring.invoice_date"
								></DatePicker>
							</v-col>
						</v-row>
						<v-row class="mb-3 px-4">
							<v-col lg="4" cols="12" class="my-auto py-0">
								<label class="input-label required">Payment Terms </label>
							</v-col>
							<v-col lg="8" class="my-auto py-0">
								<v-autocomplete
									:items="payemnt_term_list"
									hide-details
									v-model="recurring.payment_terms"
									outlined
									placeholder="Payment Terms"
									item-text="name"
									item-value="value"
									:rules="[vrules.required(recurring.payment_terms, 'Payment Terms')]"
									:class="{
										required: !recurring.payment_terms,
									}"
								></v-autocomplete>
							</v-col>
						</v-row>
						<!-- <div v-if="payemnt_term == 'Custom'">
						<div class="form-grp-title px-3 primary--text">Payment Slots</div>
						<v-row class="mb-3 px-4">
							<table class="w-100">
								<thead>
									<tr>
										<td width="33%" class="px-3 py-2">
											<label class="input-label">Payment (In Percentage)</label>
										</td>
										<td width="33%" class="px-3 py-2">
											<label class="input-label">Payment Date</label>
										</td>
										<td width="33%" class="px-3 py-2">
											<label class="input-label">Remark</label>
										</td>
									</tr>
								</thead>
								<tbody>
									<tr v-for="(row, index) in custom_payments" :key="index">
										<td width="33%" class="px-3 py-2">
											<v-text-field
												hide-details
												v-model="custom_payments[index].payment"
												outlined
												placeholder="Payment"
											></v-text-field>
										</td>
										<td width="33%" class="px-3 py-2">
											<DatePicker
												hide-details
												outlined
												custom-class="mt-0"
												placeholder="Date"
												v-model="custom_payments[index].payment_date"
											></DatePicker>
										</td>
										<td width="33%" class="px-3 py-2">
											<div class="d-flex">
												<v-text-field
													hide-details
													v-model="custom_payments[index].remark"
													outlined
													placeholder="Remark"
												></v-text-field>
												<v-btn
													v-if="index == 0"
													tile
													depressed
													color="blue darken-4"
													height="30"
													min-height="30"
													@click="add_custom_payment()"
													><v-icon color="white">mdi-plus</v-icon></v-btn
												>
												<v-btn
													v-else
													tile
													depressed
													color="red"
													height="30"
													min-height="30"
													@click="remove_custom_payment(index)"
													><v-icon color="white">mdi-close</v-icon></v-btn
												>
											</div>
										</td>
									</tr>
								</tbody>
							</table>
						</v-row>
					</div> -->
					</v-form>
				</v-col>
			</v-row>
		</div>
	</v-sheet>
</template>
<script>
import MomentJS from "moment-timezone";
import DatePicker from "@/view/components/DatePicker";
import PhoneTemplate from "@/view/components/Phone";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { GET, POST } from "@/core/services/store/request.module";
import { SET_MESSAGE, SET_ERROR } from "@/core/services/store/common.module";
import TextInput from "@/view/components/TextInput";
import { toSafeInteger } from "lodash";
export default {
	name: "Create-Recurring",
	data() {
		return {
			date_menu: false,
			formValid: true,
			pageLoading: false,
			phoneCodeList: ["SG", "AU"],
			recurringId: 0,
			salesPersonList: [
				{ name: "John", id: "1" },
				{ name: "Andrew", id: "2" },
			],
			recurring: {
				sales_person: 0,
				contact_person: null,
				company_name: null,
				primary_number: null,
				secondry_number: null,
				primary_email: null,
				secondry_email: null,
				designation: null,
				postal_code: null,
				address_line_1: null,
				address_line_2: null,
				unit_number: null,
				country: "singapore",
				currency: "sgd",
				project_type: [],
				kws: 0,
				web_url: null,
				main_contact: null,
				offered_month: null,
				contact_start_date: null,
				contact_end_date: null,
				period_time: 1,
				sales_date: null,
				invoice_primary_date: null,
				invoice_number: null,
				invoice_date: null,
				payment_terms: null,
			},
			countryList: [],
			currencyList: [],
			projectTypeList: [
				{ value: "seo", name: "Seo" },
				{ value: "web", name: "Web" },
				{ value: "domen", name: "Domen" },
				{ value: "hosting", name: "Hosting" },
				{ value: "ssl", name: "SSL" },
			],
			kwsList: [10, 20, 30, 40, 50],
			Web_urls: [
				"https://google.com.sg",
				"https://google.com.my",
				"https://google.com.us",
				"https://google.com.au",
			],
			files: [
				{
					file: null,
					name: null,
				},
			],
			datePicker: false,
			is_primary: true,
			usersList: [],

			payemnt_term_list: [
				{ value: "monthly", name: "Monthly" },
				{ value: "quarterly", name: "Quarterly" },
				{ value: "half_yearly", name: "Half Yearly" },
				{ value: "yearly", name: "Yearly" },
			],
			contact_period_list: [
				{ value: 1, name: "One Monthly" },
				{ value: 2, name: "Quarterly" },
				{ value: 3, name: "Half Yearly" },
				{ value: 4, name: "Yearly" },
			],
			/* custom_payments: [
				{
					payment: null,
					payment_date: null,
					remark: null,
				},
			], */
		};
	},

	methods: {
		updateFile(index, file) {
			if (file && file.name) {
				this.files[index].name = file.name.split(".").slice(0, -1).join(".");
				this.files[index].suffix = `.${file.name.split(".").pop()}`;
			} else {
				this.files[index].name = null;
				this.files[index].suffix = null;
			}
		},
		addMore() {
			this.files.push({
				file: null,
				name: null,
			});
		},

		removeFile(index) {
			this.files.splice(index, 1);
			if (this.files.length < 1) {
				this.addMore();
			}
		},
		get_contact_period_values() {
			if (this.recurring.contact_start_date) {
				let contactPeriodParseDate = MomentJS(this.recurring.contact_start_date);

				if (this.recurring.period_time == 4) {
					/* Yearly */
					contactPeriodParseDate = MomentJS(contactPeriodParseDate).add(1, "years");
				}

				if (this.recurring.period_time == 3) {
					/* Half Yearly*/
					contactPeriodParseDate = MomentJS(contactPeriodParseDate).add(6, "months");
				}

				if (this.recurring.period_time == 2) {
					/*Quarterly */
					contactPeriodParseDate = MomentJS(contactPeriodParseDate).add(3, "months");
				}

				if (this.recurring.period_time == 1) {
					/* Monthly */

					contactPeriodParseDate = MomentJS(contactPeriodParseDate).add(1, "months");
				}

				return (this.recurring.contact_end_date = contactPeriodParseDate.format("YYYY-MM-DD"));
			}
		},
		pageTitle() {
			if (this.uuid) {
				return this.customer.display_name + " - " + this.barcode;
			}
			return "Create new Recurring";
		},
		update_or_create() {
			const formErrors = this.validateForm(this.$refs["form"]);

			this.$refs["form"].validate();

			if (formErrors.length) {
				this.$store.commit(SET_ERROR, this.errors.concat(formErrors));
				return false;
			}

			if (!this.$refs["form"].validate()) {
				return false;
			}
			this.pageLoading = true;
			let formData = new FormData();

			formData.append("sales_person", this.recurring.sales_person ? this.recurring.sales_person : 0);
			formData.append(
				"contact_person",
				this.recurring.contact_person ? this.recurring.contact_person : null
			);
			formData.append(
				"company_name",
				this.recurring.company_name ? this.recurring.company_name : null
			);
			formData.append(
				"primary_number",
				this.recurring.primary_number ? this.recurring.primary_number : null
			);
			formData.append(
				"secondry_number",
				this.recurring.secondry_number ? this.recurring.secondry_number : 1
			);
			formData.append(
				"primary_email",
				this.recurring.primary_email ? this.recurring.primary_email : null
			);
			formData.append(
				"secondry_email",
				this.recurring.secondry_email ? this.recurring.secondry_email : null
			);
			if (this.recurring && this.recurring.designation) {
				formData.append("designation", this.recurring.designation ? this.recurring.designation : null);
			}
			if (this.recurring && this.recurring.postal_code) {
				formData.append("postal_code", this.recurring.postal_code ? this.recurring.postal_code : null);
			}
			formData.append(
				"address_line_1",
				this.recurring.address_line_1 ? this.recurring.address_line_1 : null
			);
			formData.append(
				"address_line_2",
				this.recurring.address_line_2 ? this.recurring.address_line_2 : null
			);
			if (this.recurring && this.recurring.unit_number) {
				formData.append("unit_number", this.recurring.unit_number ? this.recurring.unit_number : null);
			}
			if (this.recurring && this.recurring.country) {
				formData.append("country", this.recurring.country ? this.recurring.country : "singapore");
			}
			formData.append("currency", this.recurring.currency ? this.recurring.currency : "sgd");

			for (let i = 0; i < this.recurring.project_type.length; i++) {
				console.log(this.recurring.project_type[i]);
				formData.append(`project_type[${i}][value]`, this.recurring.project_type[i]);
			}

			/* 	formData.append("project_type", this.recurring.project_type ? this.recurring.project_type :null); */
			if (this.recurring && this.recurring.kws) {
				formData.append("kws", this.recurring.kws ? this.recurring.kws : 0);
			}
			if (this.recurring && this.recurring.web_url) {
				formData.append("web_url", this.recurring.web_url ? this.recurring.web_url : null);
			}
			if (this.recurring && this.recurring.main_contact) {
				formData.append(
					"main_contact",
					this.recurring.main_contact ? this.recurring.main_contact : null
				);
			}
			if (this.recurring && this.recurring.offered_month) {
				formData.append(
					"offered_month",
					this.recurring.offered_month ? this.recurring.offered_month : null
				);
			}
			if (this.recurring && this.recurring.contact_start_date) {
				formData.append(
					"contact_start_date",
					this.recurring.contact_start_date ? this.recurring.contact_start_date : null
				);
			}
			if (this.recurring && this.recurring.contact_end_date) {
				formData.append(
					"contact_end_date",
					this.recurring.contact_end_date ? this.recurring.contact_end_date : 0
				);
			}

			formData.append("period_time", this.recurring.period_time ? this.recurring.period_time : 1);
			if (this.recurring && this.recurring.sales_date) {
				formData.append("sales_date", this.recurring.sales_date ? this.recurring.sales_date : null);
			}
			if (this.recurring && this.recurring.invoice_primary_date) {
				formData.append(
					"invoice_primary_date",
					this.recurring.invoice_primary_date ? this.recurring.invoice_primary_date : 0
				);
			}

			if (this.recurring && this.recurring.invoice_number) {
				formData.append(
					"invoice_number",
					this.recurring.invoice_number ? this.recurring.invoice_number : null
				);
			}
			if (this.recurring && this.recurring.invoice_date) {
				formData.append(
					"invoice_date",
					this.recurring.invoice_date ? this.recurring.invoice_date : null
				);
			}
			if (this.recurring && this.recurring.payment_terms) {
				formData.append(
					"payment_terms",
					this.recurring.payment_terms ? this.recurring.payment_terms : null
				);
			}

			for (let i = 0; i < this.files.length; i++) {
				formData.append(`file[${i}][file]`, this.files[i].file);
				formData.append(`file[${i}][name]`, this.files[i].name);
			}

			let requestTYPE = POST;
			let requestURL = "recurring";

			if (this.recurringId) {
				requestURL = `recurring/${this.recurringId}`;
			}
			this.$store
				.dispatch(requestTYPE, {
					url: requestURL,
					data: formData,
				})
				.then(() => {
					if (this.recurringId > 0) {
						this.$store.commit(SET_MESSAGE, [
							{ model: true, message: "Success ! Task updated successfully." },
						]);
					} else {
						this.$store.commit(SET_MESSAGE, [
							{ model: true, message: "Success ! Task added successfully." },
						]);
					}
					this.$refs.form.reset();
					/* this.task.description = ""; */
					this.$router.replace({
						name: "recurring",
					});
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					this.pageLoading = false;
				});

			/* this.$nextTick(() => {
				if (this.recurringId > 0) {
					this.updateTaskData();
				} else {
					this.saveTaskData();
				}
			}); */
		},
		/* saveTaskData() {
			this.pageLoading = true;

			this.$store
				.dispatch(POST, { url: `recurring`, data: this.recurring })
				.then(() => {
					this.$store.commit(SET_MESSAGE, [
						{ model: true, message: "Success ! Task added successfully." },
					]);
					this.$refs.form.reset();
					
					this.$router.replace({
						name: "recurring",
					});
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		updateTaskData() {
			this.pageLoading = true;
			this.$store
				.dispatch(PUT, { url: `recurring/${this.recurringId}`, data: this.recurring })
				.then(() => {
					this.$store.commit(SET_MESSAGE, [
						{ model: true, message: "Success ! Recurring updated successfully." },
					]);
					this.$router.replace({
						name: "recurring",
					});
					this.$refs.form.reset();
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					this.pageLoading = false;
				});
		}, */
		getRecurring() {
			this.$store
				.dispatch(GET, {
					url: `single-recurring/${this.recurringId}`,
				})
				.then((data) => {
					this.recurring.sales_person = data.recurrings.sales_person;
					this.recurring.contact_person = data.recurrings.contact_person;
					this.recurring.company_name = data.recurrings.company_name;
					this.recurring.primary_number = data.recurrings.primary_number;
					this.recurring.secondry_number = data.recurrings.secondry_number;
					this.recurring.primary_email = data.recurrings.primary_email;
					this.recurring.secondry_email = data.recurrings.secondry_email;
					this.recurring.designation = data.recurrings.designation;
					this.recurring.postal_code = data.recurrings.postal_code;
					this.recurring.address_line_1 = data.recurrings.address_line_1;
					this.recurring.address_line_2 = data.recurrings.address_line_2;
					this.recurring.unit_number = data.recurrings.unit_number;
					this.recurring.country = data.recurrings.country;
					this.recurring.currency = data.recurrings.currency;
					this.recurring.project_type = JSON.parse(data.recurrings.project_type);
					this.recurring.kws = data.recurrings.kws;
					this.recurring.web_url = data.recurrings.web_url;
					this.recurring.main_contact = data.recurrings.main_contact;
					this.recurring.offered_month = data.recurrings.offered_month;
					this.recurring.contact_start_date = data.recurrings.contact_start_date;
					this.recurring.contact_end_date = data.recurrings.contact_end_date;
					this.recurring.sales_date = data.recurrings.sales_date;
					this.recurring.invoice_primary_date = data.recurrings.invoice_primary_date;
					this.recurring.invoice_number = data.recurrings.invoice_number;
					this.recurring.invoice_date = data.recurrings.invoice_date;
					this.recurring.payment_terms = data.recurrings.payment_terms;
					this.recurring.period_time = data.recurrings.period_time;
				})
				.catch((error) => {
					console.log({
						error,
					});
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		getRecurringOption() {
			this.$store
				.dispatch(GET, { url: "recurring-setting" })
				.then((data) => {
					this.usersList = data.allUsers;
					this.countryList = data.country;
					this.currencyList = data.currency;
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},

		add_custom_payment() {
			this.custom_payments.push({
				payment: null,
				payment_date: null,
				remark: null,
			});
		},
		remove_custom_payment(index) {
			this.custom_payments.splice(index, 1);
		},
	},
	components: {
		DatePicker,
		PhoneTemplate,
		TextInput,
	},
	mounted() {
		this.getRecurringOption();
		if (this.recurringId > 0) {
			this.getRecurring();
			this.pageTitle = "Update Recurring";
		}
		this.$store.dispatch(SET_BREADCRUMB, [
			{ text: "Dashboard", disabled: false, href: "/dashboard" },
			{ text: "Recurring", disabled: false, href: "/recurring" },
			{ text: "Create", disabled: true },
		]);

		if (this.recurringId) {
			this.$store.dispatch(SET_BREADCRUMB, [
				{ text: "Dashboard", disabled: false, href: "/dashboard" },
				{ text: "Recurring", disabled: false, href: "recurring/" },
				{ text: "Update", disabled: true },
			]);
		}
	},
	beforeMount() {
		this.recurringId = toSafeInteger(this.$route.params.id);
	},
};
</script>
